import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function zeroPadding(num: number) {
	return ( Array(2).join('0') + num ).slice( -2 );
}

const Timer: React.FC = () => {

    const [time, setTime] = useState<number>(0);
  
    useEffect(() => {
      const id = setInterval(() => {
        setTime(t => t + 1);
      }, 1000);
      return () => clearInterval(id);
    }, []);
  
    return (
        <Box>
          <Typography sx={{ fontSize: 22 }} component="span">
            Timer: {
              zeroPadding(Math.trunc(time/60))
            }:
            {
              zeroPadding(time%60)
            }
          </Typography>
        </Box>
    );
  };

export default Timer;
