export const Config = {
    Title: 'MPractice',
    Amplify: {
        aws_project_region: "ap-northeast-1",
        aws_cognito_region: "ap-northeast-1",
        aws_user_pools_id: "ap-northeast-1_ryGM15btO",
        aws_user_pools_web_client_id:  "276r64drnvtbati7jskl86mi6e"
    },
    ResultApiOrigin: 'https://3tjb3si956.execute-api.ap-northeast-1.amazonaws.com/prd/',
    VideoOrigin: 'https://oqzn0540ec.execute-api.ap-northeast-1.amazonaws.com/prd/'
};
