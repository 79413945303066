import Menu from "../functional/menu";
import Camera from "../functional/camera";
import ScoreSubmit from "../functional/submit";
import CameraProvider from "../functional/camera/CameraProvider";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';


const Recording: React.FC = () => {
  return (
    <Menu>
      <CameraProvider>
        <Box sx={{ flexGrow: 1}}>
          <Grid container spacing={10}>
            <Grid item>
              <Camera />
            </Grid>
            <Grid item>
              <ScoreSubmit />            
            </Grid>
          </Grid>
        </Box>
      </CameraProvider>
    </Menu>
  );
};

export default Recording;
