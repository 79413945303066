import Menu from "../functional/menu";
import Score from '../functional/score';

import { useParams } from 'react-router-dom'

const Result: React.FC = () => {
  const { id } = useParams() || '';
  console.log(id);
  return (
    <Menu>
      <Score id={id!} />
    </Menu>
  );
};

export default Result;
