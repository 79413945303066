import React, { useContext } from "react";

const stub = (): never => {
  throw new Error(
    "You forgot to wrap your component in <CognitoAuthProvider>."
  );
};

// create states
interface CameraState {
  isBlob: boolean;
  blob?: Blob;
  blobUrl?: string;
  setBlob: (blob: Blob) => void;
};
const initialState: CameraState = {
    isBlob: false,
    setBlob: () => {},
};
const initialContext = {
  ...initialState,
  setBlob: stub,
};

// create context object
export const CameraContext = React.createContext<CameraState>(initialContext);

// custom hook
export const useCamera = () => useContext(CameraContext);
