import React from 'react';
import { useEffect, useState } from 'react';

import {
  Radar, RadarChart, PolarGrid,
  PolarAngleAxis, PolarRadiusAxis, Legend
} from 'recharts';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CopyToClipboard from 'react-copy-to-clipboard';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import Modal from '@mui/material/Modal';

import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";

import { Config } from '../../static/config'
const ORIGIN_URL = Config.ResultApiOrigin;
const DOWNLOAD_URL = Config.VideoOrigin;
const w = 640, h = 480;

type Props = {
  id: string
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

const Score: React.FC<Props> = (props: Props) => {
  const [result, setResult] = useState<Array<Object>>([]);
  const [text, setText] = useState<Array<Object>>([]);
  const [id, setId] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [datetime, setDatetime] = useState<string>('');
  const [switchSpeaker, setSwitchSpeaker] = useState<boolean>(true);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [updateName, setUpdateName] = useState<string>('Anonymous');


  useEffect(() => {
    const f = async () => {
      // get token
      const user: CognitoUser = await Auth.currentAuthenticatedUser();
      const token: string = user.getSignInUserSession()?.getIdToken().getJwtToken() || '';
      const id = props.id;
  
      const myInit = {
        'Authorization': token!
      };
      let url = ORIGIN_URL + 'result?id=' + id;
  
      if (token) {
        await fetch(url, {
          headers: myInit, 
          method: 'GET'
        })
          .then(res => res.json())
          .then(data => {
             console.log(data);
              const tmp = [
                {subject: '結論ファースト', user: data.detail[0].conclusion, save: 3.9, ave: 2.2, fullMark: 5},
                {subject: '受け答え', user: data.detail[0].reaction, save: 3.5, ave: 3.1, fullMark: 5}, 
                {subject: '自然な笑顔', user: data.detail[0].smile, save: 3.8, ave: 2.3, fullMark: 5}, 
                {subject: '誠実さ・正直さ', user: data.detail[0].honesty, save: 3.4, ave: 2.5, fullMark: 5}, 
                {subject: '熱意・積極性', user: data.detail[0].aggression, save: 4.1, ave: 3.2, fullMark: 5}
              ];
              const tmpDatetime = new Date(data.detail[0].createdAt * 1000);
              setResult(tmp);
              setId(data.detail[0].id);
              setName(data.detail[0].name);
              setDatetime(tmpDatetime.toLocaleString());
              setText(data.text);
          });  

        console.log('get Url');
        await fetch(`${DOWNLOAD_URL}s3-download-url`, {
          headers: myInit, 
          method: 'POST',
          body: JSON.stringify({
            filename: id + '.mp4'
          }
        )})
          .then(res => res.json())
          .then(data => {
              console.log(data);
              setVideoUrl(data.url);
            });
      }  
    };
    f();
  }, [open]);

  const handleChange = (event: any) => {
    setSwitchSpeaker(event.target.checked);
  };

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdateName(event.target.value);
  };

  const handleSubmit = async() => {
    console.log('get token');
    // get token
    const user: CognitoUser = await Auth.currentAuthenticatedUser();
    const token: string = user.getSignInUserSession()?.getIdToken().getJwtToken() || '';
    //console.log(token);
    if (token) {
      console.log('fetch score api');
      console.log(id);
      
      const jsonBody = JSON.stringify({
        id: id,
        name: updateName,
      });

      const myInit = {
        'Authorization': token,
        'Content-Type': 'application/json'
      };
      let url = ORIGIN_URL + 'name';
      console.log(url);
      console.log(updateName);
      
      await fetch(url, {
        headers: myInit, 
        method: 'POST',
        body: jsonBody})
        .then(res => res.json())
        .then(data => {
            console.log(data);
        });  

      // calling after submitting
      handleClose();

    }
  };


  return (
    <Box sx = {{ flexGrow: 1}}>
      <Grid container spacing={5} sx={{ mb: 2 }}>
        <Grid item><Typography sx={{ fontSize: 24 }} component='div'>{datetime}</Typography></Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <Typography sx={{ fontSize: 24 }} component='div'>{name}さん</Typography>
            </Grid>
            <Grid item>
              <Button variant='contained' onClick={handleOpen} size='small'>名前更新</Button>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    名前を更新します
                  </Typography>
                      <Grid item>
                        <TextField id='Name' label='Name' defaultValue={name} variant='outlined' size='small' margin='normal' onChange={onChangeName} /><br />
                        <Button type='submit' variant="contained" sx={{}} onClick={() => handleSubmit()}>Update</Button>
                      </Grid>
                </Box>
              </Modal>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item sx={{ mr: 10 }}>
          <Grid container>
            <Grid item>
              <Typography sx={{ fontSize: 23 }} component="div">Video</Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ mt:2 }}>
            <Grid item>
              <video src={videoUrl} width={w*0.8} height={h*0.8} controls></video>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography sx={{ fontSize: 23 }} component="div">Score</Typography>
          <RadarChart  // レーダーチャート全体の設定を記述
            cx={260}  // 要素の左端とチャートの中心点との距離(0にするとチャートの左半分が隠れる)
            cy={200}  // 要素の上部とチャートの中心点との距離(0にするとチャートの上半分が隠れる)
            outerRadius={140}  // レーダーチャート全体の大きさ  
            width={500}  // レーダーチャートが記載される幅(この幅よりチャートが大きい場合、はみ出た箇所は表示されない)
            height={400}   // レーダーチャートが記載される高さ
            data={result}  // 表示対象のデータ
          >
            <PolarGrid />
            <PolarAngleAxis 
                dataKey="subject"
            />
            <PolarRadiusAxis 
                angle={90}  // 中心点から水平を0°とした時の角度 垂直にしたいなら90を指定
                tickCount={6}
                domain={[0, 5]}  // リストの１番目の要素が最小値、2番目の要素が最大値
            />
            <Radar 
                name={'初回平均'}  // そのチャートが誰のデータか指定(チャート下にここで指定した値が表示される)
                dataKey="ave"   // 表示する値と対応するdata内のキー
                stroke="#ff4099"  // レーダーの外枠の色
                fill="#ff4099"  // レーダー内の色
                fillOpacity={0.2}  // レーダー内の色の濃さ(1にすると濃さMAX)
            />
            <Radar 
                name={'合格者平均'}  // そのチャートが誰のデータか指定(チャート下にここで指定した値が表示される)
                dataKey="save"   // 表示する値と対応するdata内のキー
                stroke="#82ca9d"  // レーダーの外枠の色
                fill="#82ca9d"  // レーダー内の色
                fillOpacity={0.2}  // レーダー内の色の濃さ(1にすると濃さMAX)
            />
            <Radar 
                name={`${name}さん`}  // そのチャートが誰のデータか指定(チャート下にここで指定した値が表示される)
                dataKey="user"   // 表示する値と対応するdata内のキー
                stroke="#8884d8"  // レーダーの外枠の色
                fill="#8884d8"  // レーダー内の色
                fillOpacity={0.6}  // レーダー内の色の濃さ(1にすると濃さMAX)
            />
            <Legend />
            {
              console.log(result)
            }
          </RadarChart>
        </Grid>
      </Grid>
      {
        (videoUrl !== '') &&
        <Grid sx={{ mt: 2 }}>
          <TextField id='standard-basic' label={'videoUrl'} value={videoUrl} variant='outlined' size='small' />
          <CopyToClipboard text={videoUrl} onCopy={() => alert('動画ダウンロード用URLをコピーしました。有効期限は3時間です。')}>
            <Button variant='contained' sx={{ml: 2}}>Copy</Button>
          </CopyToClipboard>
        </Grid>
      }
      <Box sx={{ mt: 5 }}>
        {
          <Typography sx={{ fontSize: 23, mb: 1 }} component="div">Text</Typography>
        }
        {
          <FormGroup>
            <FormControlLabel 
              control={<Switch 
              defaultChecked 
              onChange={handleChange}
            />} label="Speaker" />
          </FormGroup>
        }
        {
          text.map((row: any) => {
            let color = '#000000';
            if (row.speaker === 'spk_0') {
              const speed = row.numofCharacter/row.length;
              console.log(row.speaker, speed);
              if (speed > 6) color ='#e65b35';
              return (
              <Card variant="outlined" sx={{ mb: 1.5, boxShadow: 0 }} key={row.script} >
                <CardContent style={{backgroundColor: '#ffffff'}}>
                  <Typography sx={{ mt: 0.5 }} color={color}>
                    { Math.floor(row.length)}sec / {row.numofCharacter}words
                  </Typography>
                  <Typography sx={{ fontSize: 22 }} component="div">
                    {
                      switchSpeaker &&
                      <>面接官</>
                    }
                    {
                      !switchSpeaker &&
                      <>{name}さん</>
                    }
                  </Typography>
                  <Typography variant="body1">
                    {row.script}
                  </Typography>
                </CardContent>
              </Card>);
              //return <Box>{'面接官'}: {Math.round(row.numofCharacter/row.length)} word/sec<br />{row.script}<br /></Box>;
              //{Math.floor(row.start/60)}min { Math.floor(row.start%60)}sec / { Math.floor(row.length)}sec / {row.numofCharacter}words
            } else {
              const speed = row.numofCharacter/row.length;
              if (speed > 6) color ='#e65b35';
              return (
                <Card variant="outlined" sx={{ mb: 1.5, boxShadow: 0 }}  key={row.script} >
                  <CardContent style={{backgroundColor: '#f5f5f5'}}>
                    <Typography sx={{ mt: 0.5 }} color={color}>
                      { Math.floor(row.length)}sec / {row.numofCharacter}words
                    </Typography>
                    <Typography sx={{ fontSize: 22 }} component="div">
                      {
                        switchSpeaker &&
                        <>{name}さん</>
                      }
                      {
                        !switchSpeaker &&
                        <>面接官</>
                      }
                    </Typography>
                    <Typography variant="body1">
                      {row.script}
                    </Typography>
                  </CardContent>
                </Card>);
            }
          })
        } 
      </Box>
  </Box>
  );
}

export default Score;
