import React, { memo } from "react";
import { Route, Routes } from "react-router-dom";

import Home from "../pages/home";
import Login from "../pages/login";
import UserList from "../pages/list";
import Result from "../pages/result";
import Recording from "../pages/recording";
import { useAuth } from "../functional/auth/AuthContext";
import { PrivateRoute } from "./PrivateRouter";
import { UnAuthRoute } from "./UnAuthRouter";

export const Router: React.FC = memo(() => {
  const authState = useAuth();
  console.log(authState);
  if (authState.isLoading) return null;
  console.log(authState.isAuthenticated);
  //console.log(authState.user);

  return (
      <Routes>
        {
          <Route path="/" element={<Home />} />
          //<Route path="/login" element={<Login />} />
        }
        {
          <Route
            path="/login"
            element={<UnAuthRoute isAuthenticated={authState.isAuthenticated} children={<Login />} />}
          />
        }
        {
          <Route
            path="/recording"
            element={<PrivateRoute isAuthenticated={authState.isAuthenticated} children={<Recording />} />}
          />
        }
        {
          <Route
            path="/list"
            element={<PrivateRoute isAuthenticated={authState.isAuthenticated} children={<UserList />} />}
          />
        }
        {
          <Route
            path="/result/:id"
            element={<PrivateRoute isAuthenticated={authState.isAuthenticated} children={<Result />} />}
          />
        }
      </Routes>
    );
  });
