import React from "react";

import { useState, useEffect } from "react";
import { MenuContext } from "./menuContext";

// provider params
interface IMenuParams {
  children: any;
}

export default function MenuProvider(props: IMenuParams) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
  }, []);

  const setOpen = (open: boolean) => {
      setIsOpen(open);
      console.log('calling setOpen() in menuProvider');
  }

  return (
    <MenuContext.Provider
      value={{
        isOpen,
        setOpen
      }}
    >
      {props.children}
    </MenuContext.Provider>
  );
}
