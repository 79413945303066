import { useEffect, useState } from 'react';
import { LinearProgress, Button, Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useCamera } from './camera/CameraContext';
import { v4 as uuidv4 } from 'uuid';

import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { useAuth } from './auth/AuthContext';

import axios from 'axios';

import { Config } from '../../static/config'
const SCORE_URL = `${Config.ResultApiOrigin}score`;
const VIDEO_URL = `${Config.VideoOrigin}s3-upload-url`;

const marks = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
];

const categoryList = [
  '学生',
  '転職者'
]

function valuetext(value: number) {
  return `${value}`;
}

function valueLabelFormat(value: number) {
  return marks.findIndex((mark) => mark.value === value) + 1;
}

const ScoreSubmit: React.FC = () => {
  const authState = useAuth();
  const cameraState = useCamera();

  const [id, setId] = useState<string>(uuidv4());
  const [user, setUser] = useState<string>('');

  const [isSuccess, setIsSuccess] = useState<string>();
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  const [name, setName] = useState<string>('Anonymous');
  const [category, setCategory] = useState<string>('学生');

  const [conclusion, setConclusion] = useState<number | Array<number>>(3);
  const [reaction, setReaction] = useState<number | Array<number>>(3);
  const [smile, setSmile] = useState<number | Array<number>>(3);
  const [honesty, setHonesty] = useState<number | Array<number>>(3);
  const [aggression, setAggression] = useState<number | Array<number>>(3);

  const type: string = 'video/mp4';
  const format: string = 'mp4';

  useEffect(() => {
    const f = async () => {
      const tmpUser = authState.user!.getUsername();
      console.log(user);
      setUser(tmpUser);
      setId(uuidv4());
    };
    f();
  }, []);

  const onUploadProgress = (event: any) => {
    const percentCompleted = Math.round((event.loaded * 100) / event.total);
    console.log('onUploadProgress', percentCompleted);
    setProgress(percentCompleted);
  };

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    //console.log(id);
    setName(event.target.value);
  };

  const handleConclusionChange = (event: Event, value: number | Array<number>) => {
    setConclusion(value);
    console.log(value);
  };

  const handleReactionChange = (event: Event, value: number | Array<number>) => {
    setReaction(value);
    console.log(value);
  };

  const handleSmileChange = (event: Event, value: number | Array<number>) => {
    setSmile(value);
    console.log(value);
  };

  const handleAggressionChange = (event: Event, value: number | Array<number>) => {
    setAggression(value);
    console.log(value);
  };

  const handleHonestyChange = (event: Event, value: number | Array<number>) => {
    setHonesty(value);
    console.log(value);
  };

  const handleSubmit = async() => {

    if (!isSubmit){
      setIsSubmit(true);

      console.log('get token');
      // get token
      const user: CognitoUser = await Auth.currentAuthenticatedUser();
      const token: string = user.getSignInUserSession()?.getIdToken().getJwtToken() || '';
      //console.log(token);
  
      const myInit = {
        'Authorization': token,
        'Content-Type': 'application/json'
      };
      let tmpUrl = '';
  
      if (token) {
        console.log('get VideoUpload Url');
        await fetch(VIDEO_URL, {
          headers: myInit, 
          method: 'POST',
          body: JSON.stringify({
            type: type,
            format: format,
            id: id
          }
        )})
          .then(res => res.json())
          .then(data => {
              console.log(data);
              tmpUrl = data.url;
            });
      }
      
      console.log('fetch score api');
  
      const jsonBody = JSON.stringify({
        id: id,
        name: name,
        conclusion: conclusion,
        reaction: reaction,
        smile: smile,
        aggression: aggression,
        honesty: honesty,
        interviewerId: user,
        category: category
      });
  
      await fetch(SCORE_URL, {
        headers: myInit, 
        method: 'POST',
        body: jsonBody})
        .then(res => res.json())
        .then(data => {
            console.log(data);
        });  
  
      // fetch api
      console.log('fetch s3 upload');
      setIsSuccess('');
      console.log(cameraState);
      const blob = cameraState.blob;
      const result = await axios.put(tmpUrl, blob, {onUploadProgress});
      if (result.status === 200) {
        setIsSuccess('success');
      } else {
        setIsSubmit(false);
      }
      console.log('result is', result); // result is server's response
    } else {
      console.log('cannnot submit');
    }
  };

  const handleChangeCategory = (event: SelectChangeEvent) => {
    const tmp:string = event.target.value;
    setCategory(tmp);
  };

  return (
    <Box>
      {
        cameraState.isBlob &&        
        <Box>
            <Grid container spacing={2} alignItems='center'>
              <Grid item sx={{ width: 200 }}>
                <TextField id='Name' label='Name' defaultValue={name} variant='outlined' size='small' margin='normal' onChange={onChangeName} />
              </Grid>
              <Grid item sx={{ mt: 1 }}>
                <FormControl>
                  <InputLabel id="category-select-label">Category</InputLabel>
                  <Select
                    labelId="category-select-label"
                    id="category-select"
                    value={category}
                    label="Category"
                    onChange={handleChangeCategory}
                    size='small' 
                    sx={{ width: 100 }}
                  >
                    {
                      categoryList.map((row: any) => {
                        return(
                          <MenuItem value={row} key={row}>{row}</MenuItem>    
                        );
                      })
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems='center' sx={{ mt: 1 }}>
              <Grid item sx={{ width: 100 }}>
                <>Conclusion</>
              </Grid>
              <Grid item xs>
                <Box sx={{ width: 200 }}>
                  <Slider
                    value={conclusion}
                    aria-label='Restricted values'
                    valueLabelFormat={valueLabelFormat}
                    getAriaValueText={valuetext}
                    step={null}
                    valueLabelDisplay='auto'
                    marks={marks}
                    min={1}
                    max={5}
                    onChange={handleConclusionChange}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems='center'>
            <Grid item sx={{ width: 100 }}>
              <>Reaction</>
            </Grid>
            <Grid item>
              <Box sx={{ width: 200 }}>
                <Slider
                  value={reaction}
                  aria-label='Restricted values'
                  valueLabelFormat={valueLabelFormat}
                  getAriaValueText={valuetext}
                  step={null}
                  valueLabelDisplay='auto'
                  marks={marks}
                  min={1}
                  max={5}
                  onChange={handleReactionChange}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems='center'>
            <Grid item sx={{ width: 100 }}>
              <>Smile</>
            </Grid>
            <Grid item xs>
              <Box sx={{ width: 200 }}>
                <Slider
                  value={smile}
                  aria-label='Restricted values'
                  valueLabelFormat={valueLabelFormat}
                  getAriaValueText={valuetext}
                  step={null}
                  valueLabelDisplay='auto'
                  marks={marks}
                  min={1}
                  max={5}
                  onChange={handleSmileChange}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems='center'>
            <Grid item sx={{ width: 100 }}>
              <>Honesty</>
            </Grid>
            <Grid item xs>
              <Box sx={{ width: 200 }}>
                <Slider
                  value={honesty}
                  aria-label='Restricted values'
                  valueLabelFormat={valueLabelFormat}
                  getAriaValueText={valuetext}
                  step={null}
                  valueLabelDisplay='auto'
                  marks={marks}
                  min={1}
                  max={5}
                  onChange={handleHonestyChange}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems='center'>
            <Grid item sx={{ width: 100 }}>
              <>Aggression</>
            </Grid>
            <Grid item xs>
              <Box sx={{ width: 200 }}>
                <Slider
                  value={aggression}
                  aria-label='Restricted values'
                  valueLabelFormat={valueLabelFormat}
                  getAriaValueText={valuetext}
                  step={null}
                  valueLabelDisplay='auto'
                  marks={marks}
                  min={1}
                  max={5}
                  onChange={handleAggressionChange}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ mt: 2 }}>
            <Grid item>
              <Button type='submit' variant="contained" sx={{ width: 100 }} onClick={() => handleSubmit()}>submit</Button>
              <LinearProgress variant='determinate' value={progress} />
            </Grid>
            <Grid item sx={{ mt: 0.5 }}>
              {isSuccess}
            </Grid>
          </Grid>
        </Box>
      }
    </Box>
  );
}

export default ScoreSubmit;
