import React, { useContext } from "react";
import { CognitoUser } from "amazon-cognito-identity-js";

const stub = (): never => {
  throw new Error(
    "You forgot to wrap your component in <CognitoAuthProvider>."
  );
};

interface AuthState {
  isAuthenticated: boolean;
  isLoading: boolean;
  user?: CognitoUser;
  setLoginInfo: () => void;
};
interface IAuthContext extends AuthState {
};
const initialState: AuthState = {
  isAuthenticated: false,
  isLoading: false,
  setLoginInfo: () => {},
};
const initialContext = {
  ...initialState,
};

export const AuthContext = React.createContext<IAuthContext>(initialContext);
export const useAuth = () => useContext(AuthContext);
