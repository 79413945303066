import { useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import Grid from '@mui/material/Grid';import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useCamera } from './camera/CameraContext';
import Timer from './timer';

const w = 640, h = 480;
const CHUNK_SIZE = 60 * 1000;
const audioBitrate = 128000;
const videoBitRate = 384000;

const Camera: React.FC = () => {
  const cameraState = useCamera();
  const webcamRef = useRef<Webcam>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  
  const [recorder, setRecorder] = useState<MediaRecorder>();
  const [blobUrl, setBlobUrl] = useState<string>('');
  const [recordingState, setRecordingState] = useState<'idle'|'recording'|'recorded'>('idle');
  const [chunks, setChunks] = useState<Blob[]>([]);
  const [type, setType] = useState<string>('video/mp4');
  const [options, setOptions] = useState<object>({
      audioBitsPerSecond : audioBitrate,
      videoBitsPerSecond : videoBitRate,
      mimeType : 'video/mp4'
    });
  const [devices, setDevices] = useState<object[]>([]);
  const [deviceId, setDeviceId] = useState<string>('');
  const [videoConstraints, setVideoConstraints] = useState<object>({});

  // init
  useEffect(
    () => {
      navigator.mediaDevices.enumerateDevices()
      .then((data)=>{
        const tmpDevice = data.filter(({ kind }) => kind === 'videoinput');
        // register device list
        setDevices(tmpDevice);
        setDeviceId(tmpDevice[0].deviceId);
        // initial camera
        setVideoConstraints({
          deviceId: tmpDevice[0].deviceId
        });
      });
    },
    []
  );

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    console.log(userAgent.indexOf('safari'));
    if (userAgent.indexOf('chrome') !== -1) {
      setType('video/webm');
      setOptions({
        audioBitsPerSecond : audioBitrate,
        videoBitsPerSecond : videoBitRate,
        mimeType : 'video/webm'  
      });
    }
    if (recorder) { 
      console.log('recorder start');
      if (recordingState === 'recording') {
        recorder.start(CHUNK_SIZE);
        recorder.ondataavailable = (e) =>{ 
          console.log('set blob');
          setChunks(prevState => {
            return [...prevState, e.data];
          });
        };
      }
    }
  }, [recorder]);

  const handleClickStart = async () => {    
    console.log('handle start');

    // initialize chunks
    setChunks([]);
    
    // to record camera
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true, 
      video: {
        deviceId: { exact: deviceId },
        width: w,
        height: h
      }
    });
    const tmpRecorder: MediaRecorder = new MediaRecorder(stream, options);
    setRecorder(tmpRecorder);
    videoRef.current!.srcObject = stream;
    
    // // to record screen and microphone
    // // not to use this function now
    // const mainStream = await navigator.mediaDevices.getDisplayMedia({
    //   audio: true, 
    //   video: {
    //     width: w,
    //     height: h
    //   }
    // });
    // const audioStream = await navigator.mediaDevices.getUserMedia({video: false, audio: true});
    // const stream = new MediaStream([...mainStream.getTracks(), ...audioStream.getTracks()])
    // const tmpRecorder: MediaRecorder = new MediaRecorder(stream, options);
    // setRecorder(tmpRecorder);
  };
  
  const handleClickStop = async () => {
    console.log('handle stop');
    let tmpChunks: Blob[] = [];

    recorder!.stop();
    recorder!.ondataavailable = (e) =>{ 
      console.log('set last blob');
      tmpChunks = [...chunks, e.data];
    };

    recorder!.onstop = (e) =>{ 
      console.log('total chunk', tmpChunks);
      const tmpBlob: Blob = new Blob(tmpChunks, { 'type' : type });
      cameraState.setBlob(tmpBlob);
      setBlobUrl(URL.createObjectURL(tmpBlob));
      console.log(cameraState);
    };
  };

  // const handleCheckScreen = () => {
  //   // change screen mode or not
  //   if (!checkedScreen) {
  //     setCheckedScreen(true);
  //   } else {
  //     setCheckedScreen(false);
  //   } 
  //   console.log(checkedScreen);
  // };

  const handleChangeCamera = (event: SelectChangeEvent) => {
    const tmp:string = event.target.value;
    setDeviceId(tmp);
    setVideoConstraints({
      deviceId: tmp
    });
  };

  const handleRefleshPage = () => {
    window.location.reload();
  };

  return (
    <Box>
      <Box>
        <Typography sx={{ fontSize: 22 }} component='div'>
          {
            recordingState === 'idle' &&
            <>録画準備中</>
          }
          {
            recordingState === 'recording' &&
            <Grid container spacing={2}>
              <Grid item>
                <>録画中</>
              </Grid>
              <Grid item>
                <Timer />              
              </Grid>
            </Grid>
          }
          {
            recordingState === 'recorded' &&
            <>録画済み</>
          }
        </Typography>

        {
          (recordingState === 'idle') &&
          <Box sx={{mt: 3}}>
            <FormControl>
              <InputLabel id='camera-select-label'>Camera</InputLabel>
              <Select
                labelId='camera-select-label'
                id='camera-select'
                value={deviceId}
                label='Camera'
                onChange={handleChangeCamera}
                size='small'
              >
                {
                  devices.map((row: any) => {
                    return(
                      <MenuItem value={row.deviceId} key={row.deviceId}>{row.label}</MenuItem>    
                    );
                  })
                }
              </Select>
            </FormControl>
          </Box>
        }
      </Box>
      <Box sx={{mt: 3}}>
        {
          (recordingState === 'idle') &&
          <Button variant='contained' onClick={() => { handleClickStart(); setRecordingState('recording'); }}>Recording Start</Button>
        }
        {
          (recordingState === 'recording') &&
          <Button variant='contained' onClick={() => { handleClickStop(); setRecordingState('recorded'); }}>Recording Stop</Button>
        }
        {
          (recordingState === 'recorded') &&
          <Button variant='contained' onClick={() => { handleRefleshPage(); }}>Reload</Button>
        }
      </Box>
      <Box sx={{mt: 3}}>
        {
          // This is camera preview 
          (recordingState === 'idle') &&
          <Webcam audio={false} ref={webcamRef} width={w*0.8} height={h*0.8} videoConstraints={videoConstraints} controls playsInline autoPlay />
        }
        {
          // This is camera preview 
          (recordingState === 'recording') &&
          //<Webcam audio={false} ref={webcamRef} width={w*0.8} height={h*0.8} videoConstraints={videoConstraints} playsInline autoPlay />
          <video ref={videoRef} width={w*0.8} height={h*0.8} controls playsInline autoPlay muted></video>
        }
        {
          recordingState === 'recorded' &&
          <video src={blobUrl!} width={w*0.8} height={h*0.8} controls></video>
        }
      </Box>
    </Box>
  );
}

export default Camera;
