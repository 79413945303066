import { Router } from "./components/router/Router";
import { BrowserRouter } from "react-router-dom";
import CognitoAuthProvider from "./components/functional/auth/CognitoAuthProvider";
import MenuProvider from "./components/functional/menu/menuProvider";
import { Config } from './static/config'


const App: React.FC = () => {
  return (
    <MenuProvider>
      <CognitoAuthProvider amplifyConfig={Config.Amplify}> 
          <BrowserRouter>
            <Router />    
          </BrowserRouter>
      </CognitoAuthProvider>
    </MenuProvider>
  );
};

export default App;