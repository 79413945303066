import Menu from '../functional/menu';
import { Link } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import { red } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import { useEffect, useState } from 'react';

import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { useAuth } from "../functional/auth/AuthContext";

import { Config } from '../../static/config'
const ORIGIN_URL = Config.ResultApiOrigin;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const UserList: React.FC = () => {
  const authState = useAuth();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [rows, setRows] = useState<Array<Object>>([]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
  page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const f = async () => {
      // get token
      const user: CognitoUser = await Auth.currentAuthenticatedUser();
      const token: string = user.getSignInUserSession()?.getIdToken().getJwtToken() || '';
      const id = authState.user!.getUsername(); 
  
      const myInit = {
        'Authorization': token!
      };

      const date = new Date() ;
      const ms = date.getTime() ;
      const basetime = Math.floor( ms / 1000 );    

      const start = basetime - (60 * 60 * 24 * 365);
      const end = basetime;

      let url = ORIGIN_URL + 'lists?interviewerId=' + id + '&start=' + start + '&end=' + end;
      console.log(url);
  
      if (token) {
        await fetch(url, {
          headers: myInit, 
          method: 'GET'
        })
          .then(res => res.json())
          .then(data => {
              console.log(data);
              setRows(data.list);
              console.log(data.list[0]);
          });  
      }  
    };
    f();
  }, []);

  return (
    <Menu>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: '100' }} aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell align='left'>Datetime</StyledTableCell>
              <StyledTableCell align='left'>Name</StyledTableCell>
              <StyledTableCell align='left'>Video Upload</StyledTableCell>
              <StyledTableCell align='left'>Analysis</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row: any) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell align='left'>{new Date(row.createdAt * 1000).toLocaleString()}</StyledTableCell>
                <StyledTableCell align='left'>
                  <Link
                    to={'/result/' + row.id}>
                      {row.name}
                  </Link>
                </StyledTableCell>
                <StyledTableCell align='left'>{row.isVideo === 1 ? <CheckIcon color="success" fontSize="large"/> : <CloseIcon sx={{ color: red[500] }} fontSize="large"/>}</StyledTableCell>
                <StyledTableCell align='left'>{row.isText === 1 ? <CheckIcon color="success" fontSize="large"/> : <CloseIcon sx={{ color: red[500] }} fontSize="large"/>}</StyledTableCell>
              </StyledTableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
        </TableFooter>
        </Table>
      </TableContainer>
    </Menu>
  );
};

export default UserList;
