import { memo, ReactNode } from "react";
import { Navigate } from "react-router-dom";

type Props = {
  children: ReactNode;
  isAuthenticated: boolean;
};

export const UnAuthRoute: React.FC<Props> = memo((props) => {
  const { children, isAuthenticated } = props;
  console.log("private", isAuthenticated);
  return !isAuthenticated ? (
    <>
      {children}
    </>
  ) : (
    <Navigate to="/list" />
  );
});
