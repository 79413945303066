import React, { useContext } from "react";

const stub = (): never => {
  throw new Error(
    "You forgot to wrap your component in <menuProvider>."
  );
};

// create states
interface menuState {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
};
const initialState: menuState = {
    isOpen: false,
    setOpen: () => {},
};
const initialContext = {
  ...initialState,
  setOpen: stub,
};

// create context object
export const MenuContext = React.createContext<menuState>(initialContext);

// custom hook
export const useMenu = () => useContext(MenuContext);
