import {AmplifyAuthenticator, AmplifySignIn} from "@aws-amplify/ui-react";
import { useAuth } from '../functional/auth/AuthContext';
import { useState } from 'react';

const Login: React.FC = () => {
  const [is, setIs] = useState<boolean>(false);
  const authState = useAuth();

  return is === false ? (
    <AmplifyAuthenticator
      handleAuthStateChange={(state, data) => {
        console.log(state)
        console.log(data)
        //add your logic
        if (state === 'signedin') {
          setIs(true);
          authState.setLoginInfo();
        }
    }}>
      <AmplifySignIn slot="sign-in" hideSignUp={true} />
    </AmplifyAuthenticator>
  ) : (
    <div>
      Redirecting...
    </div>
  );
};

export default Login;
