import React from "react";

import { useState, useEffect } from "react";
import { CameraContext } from "./CameraContext";

// provider params
interface ICameraParams {
  children: any;
}

export default function CameraProvider(props: ICameraParams) {
  const [isBlob, setIsBlob] = useState<boolean>(false);
  const [blob, setBlobObject] = useState<Blob>();

  useEffect(() => {
  }, []);

  const setBlob = (blob: Blob) => {
      setIsBlob(true);
      setBlobObject(blob);
      console.log('calling setBlob() in CameraProvider');
  }

  return (
    <CameraContext.Provider
      value={{
        isBlob,
        blob,
        setBlob,
      }}
    >
      {props.children}
    </CameraContext.Provider>
  );
}
